import {
  faAppleWhole,
  faBellConcierge,
  faChildReaching,
  faHouseChimney,
  faMagicWandSparkles,
  faTabletScreenButton,
  faWrench,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useMatches } from 'react-router-dom';
import { routes } from './routes';
import { AppRoute, AppRoutes } from './types';

/*******************************
 * Top-level app areas
 *
 * These are the top-level sections of the app, such as admin, parent, and staff.
 * Here we define the layout, label and navigation menu routes for each app area.
 * The router will use these to generate the route structure, and the navigation
 * menu will use them to generate the menu items.
 *******************************/

export enum APP_AREA_ID {
  root = 'root',
  admin = 'admin',
  dev = 'dev',
  frontDesk = 'frontDesk',
  parent = 'parent',
  teacherTablet = 'teacherTablet',
  kiosk = 'kiosk',
}

/**
 * Use this type to ensure that the object keys match the ids and enum values.
 */
type AppAreas = {
  [K in APP_AREA_ID]: {
    id: K;
    label: string;
    path: string;
    layout: React.LazyExoticComponent<() => JSX.Element>;
    routes: AppRoutes;
    navigationMenuGroups?: Array<{
      label: string;
      routes: AppRoute[];
      includesReportCategories?: boolean;
    }>;
    navigationMenuRoutes?: Array<AppRoute>;
    icon: IconDefinition;
  };
};

/* Instantiate the lazy-loaded component once to avoid re-rendering the layout when moving between admin and staff */
const AdminStaffLayout = React.lazy(
  () => import('../layouts/AdminStaffLayout')
);

/**
 * Defines the label and navigation menu routes for each app area.
 * Note: Routes should not be duplicated across app areas.
 */
export const appAreas: AppAreas = {
  root: {
    id: APP_AREA_ID.root,
    label: 'Beekeeper',
    path: '/',
    layout: React.lazy(() => import('../layouts/FullScreenCenteredLayout')),
    routes: routes.root,
    navigationMenuRoutes: [routes.root.home()],
    icon: faHouseChimney,
  },
  admin: {
    id: APP_AREA_ID.admin,
    label: 'Admin',
    path: '/admin',
    layout: AdminStaffLayout,
    routes: routes.admin,
    navigationMenuGroups: [
      {
        label: 'Reports',
        routes: [],
        includesReportCategories: true,
      },
      {
        label: 'Admin Services',
        routes: [
          routes.admin.services.authorizeKeys(),
          routes.admin.services.regions(),
          routes.admin.services.schools(),
          routes.admin.services.rooms(),
          routes.admin.services.programTypes(),
          routes.admin.services.programNames(),
          routes.admin.services.programDiscounts(),
          routes.admin.services.programs(),
          routes.admin.services.publicPrivateSchools(),
          routes.admin.services.ledgerItemCategories(),
          routes.admin.services.fineFeeCoupons(),
          routes.admin.services.assigningFees(),
          routes.admin.services.accountStatuses(),
          routes.admin.services.parentChecklists(),
          routes.admin.services.foodPrograms(),
          routes.admin.services.allergies(),
          routes.admin.services.medicalDocumentationTypes(),
          routes.admin.services.medicalDocumentations(),
          routes.admin.services.waivers(),
          routes.admin.services.transitionLogs(),
          routes.admin.services.teacherCountLogs(),
          routes.admin.services.schoolImporter(),
        ],
      },
      {
        label: 'Agency Assistance',
        routes: [
          routes.admin.agencyAssistance.agencies(),
          routes.admin.agencyAssistance.agencyLogs(),
        ],
      },
      {
        label: 'Front Desk Manager',
        routes: [
          routes.admin.frontDesk.generalAdmission(),
          routes.admin.frontDesk.programAdmission(),
        ],
      },
      {
        label: 'Events',
        routes: [routes.admin.event.event(), routes.admin.event.addon()],
      },
      {
        label: 'Program Calendar',
        routes: [
          routes.admin.programCalendar.seasons(),
          routes.admin.programCalendar.subjects(),
          routes.admin.programCalendar.classes(),
        ],
      },
      {
        label: 'Site Users',
        routes: [routes.admin.user.user()],
      },
      {
        label: 'Payment Gateway',
        routes: [
          routes.admin.paymentGateway.achProcessReturns(),
        ],
      },
      {
        label: 'Settings',
        routes: [routes.admin.settings.settings()],
      },
    ],
    icon: faMagicWandSparkles,
  },
  dev: {
    id: APP_AREA_ID.dev,
    label: 'Developers',
    path: '/dev',
    layout: React.lazy(() => import('../layouts/DevLayout')),
    routes: routes.dev,
    navigationMenuRoutes: [
      routes.dev.home(),
      routes.dev.theme(),
      routes.dev.formInputs(),
      routes.dev.components(),
      routes.dev.minimalForm(),
      routes.dev.minimalDataGrid(),
      routes.dev.wumpusManager(),
      routes.dev.pdfExample(),
    ],
    icon: faWrench,
  },
  frontDesk: {
    id: APP_AREA_ID.frontDesk,
    label: 'Front Desk',
    path: '/front-desk',
    layout: React.lazy(() => import('../layouts/FrontDeskLayout')),
    routes: routes.frontDesk,
    navigationMenuRoutes: [
      routes.frontDesk.quickCheckIn(),
      routes.frontDesk.programCheckIn(),
      routes.frontDesk.checkOut(),
      routes.frontDesk.bus(),
      routes.frontDesk.memberGuestGroupCheckIn(),
      routes.frontDesk.whosHere(),
      routes.frontDesk.takePayment(),
    ],
    icon: faBellConcierge,
  },
  parent: {
    id: APP_AREA_ID.parent,
    label: 'Parent Portal',
    path: '/',
    layout: React.lazy(() => import('../layouts/ParentLayout')),
    routes: routes.parent,
    navigationMenuRoutes: [
      routes.parent.home(),
      routes.parent.info(),
      routes.parent.caregivers(),
      routes.parent.children(),
      routes.parent.enroll(),
      routes.parent.programSchedule(),
      routes.parent.classSchedule(),
      routes.parent.events(),
      routes.parent.ledger(),
    ],
    icon: faChildReaching,
  },
  teacherTablet: {
    id: APP_AREA_ID.teacherTablet,
    label: 'Teacher Tablet',
    path: '/teacher-tablet',
    layout: React.lazy(() => import('../layouts/TeacherTabletLayout')),
    routes: routes.teacherTablet,
    navigationMenuRoutes: [
      routes.teacherTablet.home(),
      routes.teacherTablet.rollCall(),
      routes.teacherTablet.checkIn(),
      routes.teacherTablet.checkOut(),
    ],
    icon: faAppleWhole,
  },
  kiosk: {
    id: APP_AREA_ID.kiosk,
    label: 'Kiosk',
    path: '/kiosk',
    layout: React.lazy(() => import('../layouts/KioskLayout')),
    routes: routes.kiosk,
    icon: faTabletScreenButton,
  },
};

/**
 * Returns the current app area based on the current route.
 */
export const useAppArea = () => {
  const matches = useMatches();
  const appArea = appAreas[matches[0].id as APP_AREA_ID];
  return appArea;
};
